import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import RiskSlider from './RiskSlider';
const riskData = [
  {
    id: 'conservative',
    normal: {
      annual: 0.0782,
      stocks: 0.362,
      bonds: 0.4,
      alternatives: 0.038,
      cash: 0.2,
    },
    max: {
      annual: 0.0374,
      stocks: 0.1554,
      bonds: 0.8363,
      alternatives: 0.0084,
      cash: 0,
    },
  },
  {
    id: 'moderate',
    normal: {
      annual: 0.0869,
      stocks: 0.462,
      bonds: 0.333,
      alternatives: 0.038,
      cash: 0.167,
    },
    max: {
      annual: 0.0586,
      stocks: 0.337,
      bonds: 0.6458,
      alternatives: 0.0172,
      cash: 0,
    },
  },
  {
    id: 'balanced',
    normal: {
      annual: 0.0944,
      stocks: 0.562,
      bonds: 0.267,
      alternatives: 0.038,
      cash: 0.133,
    },
    max: {
      annual: 0.0766,
      stocks: 0.5529,
      bonds: 0.4221,
      alternatives: 0.0253,
      cash: 0,
    },
  },
  {
    id: 'growth',
    normal: {
      annual: 0.1074,
      stocks: 0.662,
      bonds: 0.3,
      alternatives: 0.038,
      cash: 0,
    },
    max: {
      annual: 0.0848,
      stocks: 0.6582,
      bonds: 0.3126,
      alternatives: 0.0292,
      cash: 0,
    },
  },
  {
    id: 'aggressive',
    normal: {
      annual: 0.115,
      stocks: 0.762,
      bonds: 0.2,
      alternatives: 0.038,
      cash: 0,
    },
    max: {
      annual: 0.0923,
      stocks: 0.7612,
      bonds: 0.2059,
      alternatives: 0.0329,
      cash: 0,
    },
  },
];

const ProductDetailsRiskSlider = ({ riskType = -1, setRiskType }) => {
  return (
    <div
      css={css`
        background: #f2f2f2;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 11px;
        margin: 40.5px 0 0;
        padding: 0 30px;
        ${Tablet} {
          flex-direction: row;
          background: none;
          padding: 0;
        }
        ${Desktop} {
          border-radius: 24.5px;
          position: absolute;
          right: 1px;
          flex-direction: column;
          background: #f2f2f2;
          margin: 40.5px 0 0;
        }
      `}
    >
      <RiskSlider
        riskData={riskData}
        value={riskType}
        onChange={(val) => {
          setRiskType(val + 1);
        }}
      />
    </div>
  );
};

export default ProductDetailsRiskSlider;
