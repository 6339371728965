import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import LongTerm from '../IndividualsLongTerm';
import { FormattedMessage, FormattedHTMLMessage } from 'gatsby-plugin-intl';

// longTermData是写在三语json里的数据，之后要是接口有相应的数据可以统一代码
const CardContent = ({ data, longTermData = false, ...style }) => {
  return (
    <div
      css={css`
        width: 100%;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;

        margin: 106px 0 0 0;
        ${Tablet} {
          width: 100%;
          margin: 80px 20px 0 0;
        }
        ${Desktop} {
          width: 746px;
          margin: 140px 20px 0 0;
        }
      `}
      {...style}
    >
      <div
        css={css`
          margin: 0px 29px 0px 0px;
          opacity: 1;
          font-weight: bold;

          font-size: 16px;
          ${Tablet} {
          }
          ${Desktop} {
            font-size: 20px;
            line-height: 1.35;
          }
        `}
      >
        {longTermData ? (
          <FormattedMessage id={`${data}.sub_title`} />
        ) : (
          data.sub_title
        )}
      </div>
      <div
        css={css`
          max-width: 706px;

          font-weight: bold;

          font-size: 25px;
          ${Tablet} {
            margin: 0px 29px 0px 0px;
          }
          ${Desktop} {
            font-size: 35px;
            line-height: 1.57;
            margin: 0px 29px 20px 0px;
          }
        `}
      >
        {longTermData ? <FormattedMessage id={`${data}.title`} /> : data.title}
      </div>
      <div
        css={css`
          max-width: 100%;
          opacity: 1;
          font-size: 16px;
          line-height: 1.56;
          margin: 10px 0 56px 0;
          ${Tablet} {
            margin: 10px 0 40px 0;
            max-width: 706px;
          }
          ${Desktop} {
            max-width: 706px;
            font-size: 20px;
            line-height: 1.5;
            margin: 0px 0px 0px 0px;
          }
        `}
      >
        {longTermData ? (
          <FormattedMessage id={`${data}.description`} />
        ) : (
          <span>{data.description}</span>
        )}
      </div>
    </div>
  );
};

export default CardContent;
