import React, { useRef, useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
// import ReactEcharts from "echarts-for-react";
import {
  useUpdateEffect,
  useWindowSize,
  useMount,
  useMountedState,
} from 'react-use';
import { RestFilled } from '@ant-design/icons';
import { useIntl } from 'gatsby-plugin-intl';
import Axios from 'axios';
// import echarts from "echarts";
var echarts = require('echarts');

const widthArr = ['99.2px', '40px', '35px', '35px', '35px', '35px'];
const textArr = ['Since Launch', 'YTD', '1M', '3M', '6M', '1Yr'];
// showPeriod 结构：{key:value} = {index:range},index表示在时间段栏中出现的顺序。
// -1:all, 0:this year, 其余数字代表月数（例：近半年-6个月）。
const showPeriod = { 0: -1, 1: 0, 2: 1, 3: 3, 4: 6, 5: 12 };
var datalist = [];
var riskDataList = {}; // 存储不同风险的回测数据，减少切换开销
// 时间段选项组件
const Period = ({
  width = '100%',
  text = '',
  active = false,
  onClick = () => {},
}) => {
  return (
    <div
      css={css`
        width: ${width};
        color: ${active ? '#ea2c7a' : '#000000'};
        background-color: ${active ? '#ffffff' : '#e5e5e5'};
        height: 23.1px;
        border-radius: 20.5px;

        font-size: 9px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        cursor: pointer;
      `}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

const BackTestingPic = ({
  showLeft = true,
  // backtesting = [],
  // benchmark = [],
  productName,
  benchmarkName,
  combinationName,
  setYearlyReturn = () => {},
  setRemarks = () => {},
  setMaxDrawDown = () => {},
  riskType,
  product,
}) => {
  const [backtesting, setBacktesting] = useState([]);
  const [benchmark, setBenchMark] = useState([]);
  const intl = useIntl();
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [myChart, setMyChart] = useState('');
  const { width: windowWidth } = useWindowSize();
  benchmarkName = intl.formatMessage({
    id: benchmarkName,
  });
  const smarthkUrl = {
    live: 'https://smarthk.aqumon.com',
    test: 'https://test-smarthk.aqumon.com',
  }[process.env.GATSBY_ENV];
  // 每次跳转新的产品页，要刷新datalist
  useMount(() => {
    datalist = [];
    riskDataList = {};
    if (!myChart) {
      setMyChart(echarts.init(echartsRef.current));
    }
    Axios.get(
      `${smarthkUrl}/api/v2/cms/product/${product.product_id}?language=en&riskType=${product.riskType}&backtesting=1`
    ).then((res) => {
      if (res.data.status.ecode) {
        // 获取失败
        myChart.hideLoading();
      } else {
        setBacktesting(res.data.data.backtesting);
        setBenchMark(res.data.data.benchmark);

        // if (benchmark.length > 0 && backtesting.length > 0) {
        //   datalist = [];
        //   if (!datalist[selectedPeriod]) {
        //     calculatePeridData(selectedPeriod);
        //   }
        //   myChart.setOption(
        //     EChartOption(
        //       datalist[selectedPeriod].backtestingData,
        //       datalist[selectedPeriod].benchmarkData
        //     )
        //   );
        //   setTimeout(() => {
        //     console.log('888888');
        //     myChart.hideLoading();
        //   }, 1000);
        // }
      }
    });
  });
  // 监听selectedPeriod的改变
  useUpdateEffect(() => {
    // datalist = [];
    if (!datalist[selectedPeriod]) {
      calculatePeridData(selectedPeriod);
    }
    myChart.clear();
    myChart.setOption(
      EChartOption(
        datalist[selectedPeriod].backtestingData,
        datalist[selectedPeriod].benchmarkData
      )
    );
  }, [selectedPeriod]);
  // 监听showLeft的变化
  useUpdateEffect(() => {
    datalist = [];
    if (!datalist[selectedPeriod]) {
      calculatePeridData(selectedPeriod);
    }
    myChart.setOption(
      EChartOption(
        datalist[selectedPeriod].backtestingData,
        datalist[selectedPeriod].benchmarkData
      )
    );
  }, [showLeft]);
  // 监听benchmark的变化。切换风险类型后会重新set backtesting和benchmark数据，会有异步延迟。故监听benchmark数据变化，重新画图。
  useUpdateEffect(() => {
    if (myChart) {
      datalist = [];
      if (!datalist[selectedPeriod]) {
        calculatePeridData(selectedPeriod);
      }
      myChart.setOption(
        EChartOption(
          datalist[selectedPeriod].backtestingData,
          datalist[selectedPeriod].benchmarkData
        )
      );
      setTimeout(() => {
        myChart.hideLoading();
      }, 1000);
    }
  }, [benchmark]);

  // 因为riskType变化后，会执行setProduct，异步有延迟，故直接监听product的变化，执行setBacktesting和setBenchMark操作。
  useUpdateEffect(() => {
    myChart.showLoading('default', { color: '#ee2f79' });
    if (!riskDataList[riskType]) {
      // 若切换后风险对应的回测数据未曾存储，则请求该风险的回测数据
      Axios.get(
        `${smarthkUrl}/api/v2/cms/product/${product.product_id}?language=en&riskType=${product.riskType}&backtesting=1`
      ).then((res) => {
        if (res.data.status.ecode) {
          // 获取失败
        } else {
          setBacktesting(res.data.data.backtesting);
          setBenchMark(res.data.data.benchmark);
          riskDataList[riskType] = {
            backtesting: res.data.data.backtesting,
            benchmark: res.data.data.benchmark,
          };
          // datalist = [];
          setTimeout(() => {
            myChart.hideLoading();
          }, 1000);
        }
      });
    } else {
      setBacktesting(riskDataList[riskType].backtesting);
      setBenchMark(riskDataList[riskType].benchmark);
      // datalist = [];
      setTimeout(() => {
        myChart.hideLoading();
      }, 1000);
    }
  }, [product]);

  // 当数字是小于10的就要在前面加0,使其规范
  const checkTime = (num) => {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  };
  // 比较两个时间的大小
  const compareTime = (date1, date2) => {
    // date1>date2:true
    var oDate1 = new Date(date1);
    var oDate2 = new Date(date2);
    if (oDate1.getTime() > oDate2.getTime()) {
      // 转换成毫秒进行比较
      return true;
    } else {
      return false;
    }
  };
  // 对数据进行归一化处理
  const rebase = (dataList) => {
    if (dataList.length == 0) return [];
    const firstValue = dataList[0]['value'];
    return dataList.map((item) => {
      return ((item['value'] - firstValue) / firstValue) * 100;
    });
  };
  // 计算最大回撤
  const maxDrawdown = (backtesting) => {
    if (backtesting.length == 0) {
      return 0;
    }
    var maxDrawDownValue = 0.0;
    var tempMaxValue = backtesting[0]['value'];
    for (var i = 1; i < backtesting.length; i++) {
      var currentValue = backtesting[i]['value'];
      tempMaxValue = Math.max(tempMaxValue, currentValue);
      maxDrawDownValue = Math.max(
        maxDrawDownValue,
        (tempMaxValue - currentValue) / tempMaxValue
      );
    }
    return maxDrawDownValue;
  };
  const echartsRef = useRef();

  // 取两个数组日期的并集
  const intersection = (backtesting, benchmark) => {
    // backtesting与benchmark数组的结构均为：[{date: '' ,value: ''}, {date: '' ,value: ''}, ...]
    let backtestingIndex = 0; // 记录backtesting数组的下标
    let benchmarkIndex = 0; // 记录benchmark数组的下标
    let resultBacktesting = []; // 存储date为交集的backtesting数据
    let resultBenchmark = []; // 存储date为交集的benchmark数据
    // 因为backtesting与benchmark两个数组中的date都是递增的，可以利用这一点，同时遍历两个数组，获得date为交集的结果数组。
    while (
      backtestingIndex <= backtesting.length - 1 &&
      benchmarkIndex <= benchmark.length - 1
    ) {
      // 比较时间戳的大小
      if (
        backtesting[backtestingIndex].date === benchmark[benchmarkIndex].date
      ) {
        resultBacktesting.push(backtesting[backtestingIndex]);
        resultBenchmark.push(benchmark[benchmarkIndex]);
        backtestingIndex++;
        benchmarkIndex++;
      } else if (
        new Date(backtesting[backtestingIndex].date).getTime() >
        new Date(benchmark[benchmarkIndex].date).getTime()
      ) {
        benchmarkIndex++;
      } else {
        backtestingIndex++;
      }
    }
    return { backtesting: resultBacktesting, benchmark: resultBenchmark };
  };

  // 截取数据的显示范围
  const changeData = (period) => {
    // today:回测数据中最新的一天
    const today = new Date(backtesting.slice(-1)[0]['date']);
    const dataList = backtesting.map(
      (item) => +new Date(item['date'].replace(/-/g, '/'))
    );
    var year = today.getFullYear();
    var month = today.getMonth() + 1;
    var day = today.getDate();
    var calculateData = {
      backtestingData: {},
      benchmarkData: {},
    };
    if (period === -1) {
      // show all
      // calculateData.backtestingData = backtesting.concat([]);
      // calculateData.benchmarkData = benchmark;

      // 计算backtesting数组与benchmark数组中date的并集后再画图
      let intercectionData = intersection(backtesting.concat([]), benchmark);
      calculateData.backtestingData = intercectionData.backtesting;
      calculateData.benchmarkData = intercectionData.benchmark;
    } else if (period === 0) {
      // show YTD
      day = 1;
      var time = `${year}/01/${checkTime(day)}`;
      // while (dataList.indexOf(time) == -1) {
      //   day++;
      //   time = `${year}/01/${checkTime(day)}`;
      // }
      let thisTimeStemp = +new Date(time);
      var index = dataList.findIndex((timestemp) => timestemp >= thisTimeStemp);

      // calculateData.backtestingData = backtesting.slice(index);
      // calculateData.benchmarkData = benchmark.slice(index);

      // 计算backtesting数组与benchmark数组中date的并集后再画图
      let intercectionData = intersection(
        backtesting.slice(index),
        benchmark.slice(index)
      );
      calculateData.backtestingData = intercectionData.backtesting;
      calculateData.benchmarkData = intercectionData.benchmark;
    } else {
      var range = period;
      if (month - range <= 0) {
        year--;
        month = 12 + month - range;
      } else {
        month = month - range;
      }

      var time = `${year}/${checkTime(month)}/${checkTime(day)}`;

      // 如果没有time这天的数据，就向后一天找，直至找到最近的有数据的一天为止
      // while (dataList.indexOf(time) == -1) {
      //   var nextDay = new Date(new Date(time).getTime() + 86400000); //一天有86400000毫秒
      //   year = nextDay.getFullYear();
      //   month = nextDay.getMonth() + 1;
      //   day = nextDay.getDate();
      //   time = `${year}/${checkTime(month)}/${checkTime(day)}`;
      // }
      let thisTimeStemp = +new Date(time);
      var index = dataList.findIndex((timestemp) => timestemp >= thisTimeStemp);
      // var index = dataList.indexOf(time);
      // calculateData.backtestingData = backtesting.slice(index);
      // calculateData.benchmarkData = benchmark.slice(index);

      // 计算backtesting数组与benchmark数组中date的并集后再画图
      let intercectionData = intersection(
        backtesting.slice(index),
        benchmark.slice(index)
      );
      calculateData.backtestingData = intercectionData.backtesting;
      calculateData.benchmarkData = intercectionData.benchmark;
    }
    return calculateData;
    // echartsRef.current.getEchartsInstance().setOption(EChartOption());
    // myChart.setOption(EChartOption());
  };

  const calculatePeridData = (peridIndex) => {
    datalist[peridIndex] = changeData(showPeriod[peridIndex]);
  };

  const EChartOption = (backtesting, benchmark) => {
    // 两组数据整合
    const seriesData = [
      {
        name: 'backtesting',
        dataList: rebase(backtesting),
      },
      {
        name: 'benchmark',
        dataList: rebase(benchmark),
      },
    ];

    // 最大回撤，历史累计收益率，基准
    setMaxDrawDown((maxDrawdown(backtesting) * 100).toFixed(2) + '%');
    setYearlyReturn(
      Number(seriesData[0]['dataList'].slice(-1)).toFixed(2) + '%'
    );
    setRemarks(Number(seriesData[1]['dataList'].slice(-1)).toFixed(2) + '%');

    // 设置y轴刻度间隔
    const findInterval = (seriesData) => {
      // let max = seriesData[0]['dataList'][0];
      // let min = seriesData[0]['dataList'][0];
      let max = 0;
      let min = 0;
      const step = 2;

      seriesData.forEach((item) => {
        const valueList = item['dataList'];
        const dataListMax = Math.max.apply(null, valueList);
        const dataListMin = Math.min.apply(null, valueList);

        if (max < dataListMax) {
          max = dataListMax;
        }
        if (min > dataListMin) {
          min = dataListMin;
        }
      });
      const interval = +((max.toFixed(2) - min.toFixed(2)) / step);
      return interval;
    };

    const option = {
      // height: 400,
      baseOption: {
        // width: "50%",

        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'rgba(255,255,255,1)',
          borderColor: '#b6ccdc',
          textStyle: {
            color: '#8E9091;',
          },
          extraCssText: 'z-index: 2',
          formatter: function(params) {
            if (params.length === 0) return;
            var res = params[0].name + '<br/>';
            for (var i = 0; i < params.length; i++) {
              res += `${params[i].marker} ${params[i].seriesName} : ${params[
                i
              ].value.toFixed(2)}%<br/>`;
            }
            return res;
          },
        },
        legend: {
          data: [productName, benchmarkName],
          icon: 'roundRect',
          bottom: 6,
          itemWidth: 20,
          itemHeight: 7,
          tooltip: {
            show: true,
            confine: true,
            formatter: function(param) {
              // 对于benchmark的图例做解释,不对产品的图例进行解释
              var res = '';
              var benchmarkInterpretation = '';
              if (productName !== param.name) {
                benchmarkInterpretation = intl.formatMessage({
                  id: `productDetails.${combinationName}.benchmark-interpretation`,
                });
                res = `<div style="max-width:300px; white-space:normal;" >${benchmarkInterpretation}</div>`;
              }
              return res;
            },
          },
        },
        grid: {
          right: '20',
          left: '70',
        },
        xAxis: {
          // X轴
          boundaryGap: false,
          type: 'category', // 不使用time因为刻度不能自定义
          splitLine: {
            show: false,
          },
          splitNumber: 2,
          axisLabel: {
            color: 'rgb(156, 156, 156)',
            formatter(value, index) {
              const xData = backtesting.map((item) => item['date']);
              if (index === 0) {
                return `                  ${value}`;
              }
              if (index === xData.length - 1) {
                return `${value}                        `;
              }
              return value;
            },
            align: 'center',
            padding: [4, 0, 0, 0],
            interval(index) {
              const length = backtesting.length;
              if (!length) {
                return true;
              }
              const xData = backtesting.map((item) => item['date']);
              const split = parseInt(xData.length / 2, 10);
              return index % split === 0 || index === xData.length - 1;
            },
          },
          data: backtesting.map((item) => item['date']),
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          // splitNumber: 2,
          interval: findInterval(seriesData),
          min: 'dataMin',
          max: 'dataMax',
          axisLabel: {
            color: 'rgb(156, 156, 156)',
            formatter(value) {
              let temp = '';
              temp = `${value.toFixed(2)}%`;
              return temp;
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: productName,
            type: 'line',
            data: seriesData[0]['dataList'],
            showSymbol: true,
            symbol: 'circle',
            symbolSize: 2,
            lineStyle: {
              width: 1,
            },
            itemStyle: {
              color: '#ee2f79',
            },
            animationThreshold: 400,
          },
          {
            name: benchmarkName,

            type: 'line',
            data: seriesData[1]['dataList'],
            showSymbol: true,
            symbol: 'circle',

            symbolSize: 2,
            lineStyle: {
              width: 1,
            },
            itemStyle: {
              color: '#a7a7a7',
            },
            animationThreshold: 400,
          },
        ],
      },
    };
    return option;
  };
  const isMounted = useMountedState();

  // calculateData()
  useEffect(() => {
    // if (!myChart) {
    //   setMyChart(echarts.init(echartsRef.current));
    //   console.log('88888');
    // }
    // console.log('firstDrawChart', firstDrawChart);
    // if (firstDrawChart) return;
    // if (isMounted() && benchmark.length > 0 && backtesting.length > 0) {
    //   firstDrawChart = true;
    //   console.log('useEffect', firstDrawChart);
    //   myChart.showLoading('default', { color: '#ee2f79' });
    //   datalist = [];
    //   if (!datalist[selectedPeriod]) {
    //     calculatePeridData(selectedPeriod);
    //   }
    //   if (timer) {
    //     return;
    //   }
    //   timer = setTimeout(() => {
    //     console.log('useEffect2');
    //     timer = null;
    //   }, 500);
    //   setTimeout(() => {
    //     myChart.hideLoading();
    //   }, 1000);
    // }
  });
  useUpdateEffect(() => {
    myChart.showLoading('default', { color: '#ee2f79' });
    // changeData();
    myChart.setOption(EChartOption(backtesting, benchmark));
    document.addEventListener('click', (event) => {
      myChart.dispatchAction({
        type: 'legendAllSelect',
      });
      if (event.target.tagName != 'CANVAS') {
        myChart.dispatchAction({
          type: 'hideTip',
        });
      }
    });
  }, [myChart]);
  // 监听屏幕宽度的变化，重绘回测图
  useUpdateEffect(() => {
    // myChart.setOption(EChartOption(),true);
    myChart.resize();
  }, [windowWidth]);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        // height: 420px;
        margin-right: 0px;
        ${Tablet} {
          width: 100%;
          // width: 746px;
          // height: 472.6px;
          margin-right: 20px;
        }
        ${Desktop} {
          // width:100%;
          width: 746px;
          // height: 472.6px;
          margin-right: 20px;
        }
      `}
    >
      {/* 图 */}
      <div
        css={css`
          width: 100%;
          height: 100%;
          height: 444px;
          ${Tablet} {
            width: 100%;
            // width: 746px;
            height: 444px;
          }
          ${Desktop} {
            // width:80%;
            width: 746px;
            height: 444px;
          }
        `}
      >
        <div id='line-charts' ref={echartsRef} style={{ height: '100%' }}></div>
        {/* <ReactEcharts
          ref={echartsRef}
          option={EChartOption()}
          style={{ height: "100%" }}
          className='react_for_echarts'
          // noMerge={false}
          //  lazyUpdate={true}
        /> */}
      </div>

      {/* 时间段栏 */}
      <div
        css={css`
          display: flex;
          justify-content: space-around;
          position: relative;
          width: 260px;
          height: 28.4px;
          padding: 2.2px 2px 3px 2px;
          border-radius: 24.5px;
          background-color: #e5e5e5;
          margin: 5.8px auto 0;
          ${Tablet} {
          }
          ${Desktop} {
          }
        `}
      >
        {new Array(6).fill(0).map((val, i) => {
          return (
            <Period
              key={i}
              width={widthArr[i]}
              index={i}
              text={textArr[i]}
              active={i == selectedPeriod}
              onClick={() => {
                setSelectedPeriod(i);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BackTestingPic;
