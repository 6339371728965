import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import Container from '../Container';

const SuitableCard = ({ data = {} }) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        max-width: 377px;
        width: fit-content;
        margin: 38.2px 0 0 0;
        ${Tablet} {
          margin: 0;
        }
        ${Desktop} {
          margin: 0;
        }
      `}
    >
      <img
        css={css`
          // width: 46.5px;
          height: 39.6px;
          ${Tablet} {
            // width: 46.5px;
            height: 39.6px;
          }
          ${Desktop} {
            // width: 65px;
            height: 37px;
          }
        `}
        src={intl.formatMessage({
          id: `${data}.picture`,
        })}
      ></img>
      <div
        css={css`
          font-weight: bold;
          font-size: 16px;
          ${Tablet} {
          }
          ${Desktop} {
            font-size: 25px;
          }
        `}
      >
        {intl.formatMessage({
          id: `${data}.title`,
        })}
      </div>
      <div
        css={css`
          margin: 19.3px 10px 6.5px 0px;
          font-weight: normal;
          text-align: left;
          font-size: 16px;
          line-height: 1.25;
          ${Tablet} {
            font-size: 12px;
            line-height: 1.25;
          }
          ${Desktop} {
            font-size: 20px;
            line-height: 1.75;
          }
        `}
      >
        {intl.formatMessage({
          id: `${data}.description`,
        })}
      </div>
    </div>
  );
};

const Suitable = ({ data = {} }) => {
  const intl = useIntl();
  return (
    <Container
      css={css`
        letter-spacing: normal;
        font-stretch: normal;
        font-style: normal;

        color: #000000;
        padding: 0px;
        margin: 80px 0 0 0;
        ${Tablet} {
          width: 100%;
        }
        ${Desktop} {
          margin: 140px 24px 45.6px 0px;
        }
      `}
    >
      <div
        css={css`
          font-weight: bold;
          text-align: left;
          font-size: 25px;
          ${Tablet} {
            font-size: 25px;
            margin: 0px 29px 10px 0px;
          }
          ${Desktop} {
            font-size: 35px;
            line-height: 1.57;
            margin: 0px 29px 20px 0px;
          }
        `}
      >
        <FormattedMessage id={`${data}.title`} />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          ${Tablet} {
            flex-direction: row;
          }
          ${Desktop} {
            height: 347px;
            flex-direction: row;
          }
        `}
      >
        {/* 三个优势卡片 */}
        {new Array(3).fill(0).map((val, index) => {
          return (
            <SuitableCard data={`${data}.suitable-${index + 1}`} key={index} />
          );
        })}
      </div>
    </Container>
  );
};

export default Suitable;
