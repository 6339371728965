import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import StableAndLiquid from '../IndividualsStableAndLiquid';

const PicBox1 = ({ data = {}, type = 'active-approach', img = '' }) => {
  const intl = useIntl();
  // 该组件部分不同类型产品的字体大小不一样
  const fontSizeMap = {
    'long-term': [
      { value_1: '35px', value_2: '20px' }, // 手机端
      { value_1: '35px', value_2: '20px' }, // 平板端
      { value_1: '50px', value_2: '20px' }, // web端
    ],
    'active-approach': [
      { value_1: '60px', value_2: '20px' },
      { value_1: '60px', value_2: '20px' },
      { value_1: '90px', value_2: '25px' },
    ],
    'stable-and-liquid': [
      { value_1: '20px', value_2: '60px' },
      { value_1: '20px', value_2: '60px' },
      { value_1: '40px', value_2: '80px' },
    ],
  };
  return (
    <div
      css={css`
        // display: flex;
        // justify-content: center;
        // align-items: center;
        transform: translateY(-25px);
        // opacity: 0.49;
        border-radius: 20px;
        background: url(${img}) center/auto 100% no-repeat border-box border-box;
        background-size: cover;

        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #ffffff;

        width: 335px;
        height: 120px;
        align-self: center;
        margin: 0 auto;
        ${Tablet} {
          width: 335px;
          height: 120px;
          align-self: center;
          // padding: 0 15px;
          margin: 0 auto;
        }
        ${Desktop} {
          width: 392px;
          height: 140px;
          margin: 0;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          background: rgba(0, 0, 0, 0.4);
          width: 335px;
          height: 120px;
          ${Tablet} {
            width: 335px;
            height: 120px;
            padding: 0 15px;
          }
          ${Desktop} {
            width: 392px;
            height: 140px;
            margin: 0;
          }
        `}
      >
        <span
          css={css`
            font-weight: bold;

            text-align: center;

            font-size: ${fontSizeMap[type][0]['value_1']};
            // line-height: 125px;
            margin: 0 5px 0 5px;
            ${Tablet} {
              font-size: ${fontSizeMap[type][1]['value_1']};
              line-height: 125px;
              margin: 0 10px 0 0px;
            }
            ${Desktop} {
              font-size: ${fontSizeMap[type][2]['value_1']};
              margin: 0 17px 0 0px;
            }
          `}
        >
          <FormattedMessage id={`${data}.card-1.value`} />
        </span>
        <span
          css={css`
            // height: 36px;
            font-weight: normal;
            text-align: left;
            position: relative;
            margin: 41px 0 42px 0px;

            font-size: ${fontSizeMap[type][0]['value_2']};
            // line-height: 1.5;
            // top: -14px;
            ${Tablet} {
              font-size: ${fontSizeMap[type][1]['value_2']};
              // line-height: 1.5;
              // top: -14px;
            }
            ${Desktop} {
              font-size: ${fontSizeMap[type][2]['value_2']};
            }
          `}
        >
          <FormattedMessage id={`${data}.card-1.text`} />
        </span>
      </div>
    </div>
  );
};

const PicBox2 = ({ data1 = {}, data2 = {}, img = '' }) => {
  // data1:接口数据,data2:本地数据
  const intl = useIntl();
  const [showMore, setShowMore] = useState(false);
  return (
    <div
      css={css`
        display: inline-block;
        border-radius: 20px;
        background: url(${img}) center/auto 100% no-repeat border-box border-box;
        background-size: cover;

        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #ffffff;

        width: 335px;
        align-self: center;
        margin: 40px 0 0 0;
        ${Tablet} {
          margin: 20px 0 0 0;
        }
        ${Desktop} {
          width: 392px;
          margin: 167px 0 0 0;
          vertical-align: top;
        }
      `}
    >
      <div
        css={css`
          background: rgba(0, 0, 0, 0.4);
          border-radius: 20px;
          width: 335px;
          padding: 23px 10px 10px 10px;
          ${Tablet} {
          }
          ${Desktop} {
            width: 392px;
            padding: 0px 20px 12.6px 20px;
          }
        `}
      >
        <span
          css={css`
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            vertical-align: middle;

            display: inline-block;
            font-size: 55px;
            line-height: 73px;
            ${Tablet} {
            }
            ${Desktop} {
              display: inline-block;
              font-size: 65px;
              line-height: 106px;
            }
          `}
        >
          {data1.value_1}
        </span>
        <span
          css={css`
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
            vertical-align: middle;

            display: inline-block;

            font-size: 20px;
            line-height: 1.25;
            max-width: 150px;
            ${Tablet} {
            }
            ${Desktop} {
              display: inline-block;
              max-width: 140px;

              font-size: 20px;
              line-height: 1.25;
              margin-left: 6px;
            }
          `}
        >
          {data1.metric_1}
        </span>
        <div>
          <div
            css={css`
              display: ${showMore ? 'block' : 'none'};
              font-size: 12px;
              height: 100px;
              margin-bottom: 10px;
              ${Tablet} {
              }
              ${Desktop} {
                height: 83px;
                margin-top: 0;
              }
            `}
          >
            {intl.formatMessage({
              id: `${data2}.description`,
            })}
          </div>
          <DownOutlined
            css={css`
              display: ${showMore ? 'none' : 'inline'};
              font-size: 25px;
              padding: 0 140px;
              ${Tablet} {
              }
              ${Desktop} {
                padding: 0 165px;
              }
            `}
            onClick={() => {
              setShowMore(true);
            }}
          />
          <UpOutlined
            css={css`
              display: ${showMore ? 'inline' : 'none'};
              font-size: 25px;
              padding: 0 140px;
              ${Tablet} {
              }
              ${Desktop} {
                padding: 0 165px;
              }
            `}
            onClick={() => {
              setShowMore(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { PicBox1, PicBox2 };
