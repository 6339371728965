import React from 'react';
import { css } from '@emotion/core';
import Container from '../Container';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import ReactSlider from 'react-slider';
import { Desktop, Tablet } from '../../constants/responsive';

const RiskSlider = ({ riskData, value, onChange }) => {
  return (
    <Container
      css={css`
        margin-top: 31.5px;
        margin-bottom: 13.6px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
        min-width: 0;
        ${Tablet} {
          margin-top: 30px;
          width: 378px;
          flex-direction: row;
          height: 43.39px;
          background: #f2f2f2;
          border-radius: 24.5px;
        }
        ${Desktop} {
          margin-top: 38.9px;
          margin-bottom: 15.4px;
          width: 392px;
          flex-direction: column;
          height: 60px;
        }
      `}
    >
      <div
        css={css`
          font-size: 11px;
          display: none;
          ${Tablet} {
            display: block;
          }
          ${Desktop} {
            display: none;
            font-size: 11px;
          }
        `}
      >
        <FormattedHTMLMessage id='individuals.portfolio.low' />
      </div>
      <div
        css={css`
          position: relative;
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          margin: 0;
          .slider {
            width: calc(60vw + 12px);
            height: 28px;
          }
          ${Tablet} {
            margin: 0px 10px;
            .slider {
              width: 259px;
              height: 43.39px;
            }
          }
          ${Desktop} {
            margin: 0px 20px;
            .slider {
              width: 356.8px;
              height: 40px;
            }
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 60vw;
            // min-width: 192px;
            ${Tablet} {
              // width: 259px;
              width: 95%;
            }
            ${Desktop} {
              width: 353.8px;
            }
          `}
        >
          {[1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1].map(
            (big, index) => {
              const size = big ? [13.5, 13.5, 18.5] : [6, 6, 8.1];
              return (
                <div
                  key={`cicle-${big}-${index}`}
                  css={css`
                    width: ${size[0]}px;
                    height: ${size[0]}px;
                    border-radius: ${size[0]}px;
                    ${Tablet} {
                      width: ${size[1]}px;
                      height: ${size[1]}px;
                      border-radius: ${size[1]}px;
                    }
                    ${Desktop} {
                      width: ${size[2]}px;
                      height: ${size[2]}px;
                      border-radius: ${size[2]}px;
                    }
                    background: #d5d5d5;
                  `}
                />
              );
            }
          )}
        </div>
        <ReactSlider
          value={value}
          step={1}
          min={0}
          max={4}
          onChange={onChange}
          renderThumb={(props, state) => (
            <div
              css={css`
                text-align: center;
                cursor: grab;
                transition: all 0.5s ease;
                transform: translateX(0);
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                // border: solid 3px #ffffff;
                background-color: #555555;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 24.4px;
                width: 23.7px;
                border-radius: 25px;
                ${Tablet} {
                  height: 24.4px;
                  width: 23.7px;
                  border-radius: 25px;
                  top: 10px;
                }
                ${Desktop} {
                  height: 32.4px;
                  width: 32.4px;
                  border-radius: 32.4px;
                  top: 3.5px;
                }
              `}
              {...props}
              style={{
                transform: `translateX(${props.style.left})`,
                position: 'absolute',
                touchAction: 'none',
              }}
            >
              <div
                css={css`
                  position: absolute;
                  font-size: 11px;
                  font-weight: bold;
                  top: -20px;
                  white-space: nowrap;
                  ${Tablet} {
                    top: -28px;
                  }
                  ${Desktop} {
                    top: -25px;
                    font-size: 12px;
                  }
                `}
              >
                <FormattedHTMLMessage
                  id={`individuals.portfolio.${riskData[state.valueNow].id}`}
                />
              </div>
              {[1, 2, 3].map((i) => {
                return (
                  <div
                    css={css`
                      background: white;
                      border-radius: 3px;
                      width: 12px;
                      height: 2px;
                      margin: 1px 0px;
                      ${Tablet} {
                        width: 12px;
                        height: 2px;
                        margin: 1.5px 0px;
                      }
                      ${Desktop} {
                        width: 16.4px;
                        height: 2px;
                        margin: 2px 0px;
                      }
                    `}
                    key={i}
                  />
                );
              })}
            </div>
          )}
        />
      </div>
      <div
        css={css`
          font-size: 11px;
          display: none;
          ${Tablet} {
            display: block;
          }
          ${Desktop} {
            font-size: 11px;
            display: none;
          }
        `}
      >
        <FormattedHTMLMessage id='individuals.portfolio.high' />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          width: calc(60vw + 12px);
          ${Tablet} {
            display: none;
          }
          ${Desktop} {
            width: 100%;
            padding: 0 20px;
            display: flex;
          }
        `}
      >
        <div
          css={css`
            font-size: 11px;

            ${Desktop} {
              font-size: 11px;
            }
          `}
        >
          <FormattedHTMLMessage id='individuals.portfolio.low' />
        </div>

        <div
          css={css`
            font-size: 11px;
            ${Desktop} {
              font-size: 11px;
            }
          `}
        >
          <FormattedHTMLMessage id='individuals.portfolio.high' />
        </div>
      </div>
    </Container>
  );
};

export default RiskSlider;
