import React, { useState } from "react";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";
import {
  FormattedMessage,
  useIntl,
  FormattedHTMLMessage,
} from "gatsby-plugin-intl";
import Container from "../Container";
import ScrollContainer from "react-indiana-drag-scroll";

const Card = ({ text = "", img = "" }) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        background: url(${intl.formatMessage({ id: img })}) center/auto 100%
          no-repeat border-box border-box;
        background-size: cover;
        position: relative;
        overflow: hidden;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        width: 227.1px;
        height: 210px;
        margin: 0 10px 0 0;
        padding: 0px;
        border-radius: 11px;
        ${Tablet} {
          margin: 0;
          width: 171px;
          height: 207.1px;
          padding: 0;
          border-radius: 11px;
        }
        ${Desktop} {
          margin: 0;
          width: 353px;
          height: 251.4px;
          padding: 0;
          border-radius: 20px;
        }
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          padding: 0 20px;
          background: rgba(0, 0, 0, 0.28);
          width: 100%;
          height: 100%;
          left: 0;
          text-align: left;
          font-weight: normal;
          font-size: 16px;
          line-height: 1.25;
          color: #ffffff;
          ${Tablet} {
            padding: 0 15px;
            font-size: 12px;
            line-height: 1.25;
          }
          ${Desktop} {
            padding: 0 20px;
            font-size: 20px;
            line-height: 1.5;
          }
        `}
      >
        <FormattedHTMLMessage id={text} />
      </div>
    </div>
  );
};

const HowItWorks = ({ data = {} }) => {
  const intl = useIntl();
  return (
    <Container
      css={css`
        letter-spacing: normal;
        font-stretch: normal;
        font-style: normal;
      
        color: #000000;
        padding: 0px;
        margin: 80px 0 0 0;
        ${Tablet} {
          width: 100%;
        }
        ${Desktop} {
          margin: 140px 24px 45.6px 0px;
        }
      `}
    >
      <div
        css={css`
          font-weight: bold;
          text-align: left;
          font-size: 25px;
          ${Tablet} {
            font-size: 25px;
            margin: 0px 29px 10px 0px;
          }
          ${Desktop} {
            font-size: 35px;
            line-height: 1.57;
            margin: 0px 29px 20px 0px;
          }
        `}
      >
        <FormattedMessage id={`${data}.title`} />
      </div>
      <ScrollContainer
        css={css`
          display: flex;
          justify-content: space-between;
          ${Tablet} {
            display: none;
          }
        `}
      >
        {new Array(3).fill(1).map((val, index) => {
          return (
            <Card
              text={`${data}.description-${index + 1}`}
              img={`${data}.img-${index + 1}`}
              key={index}
            />
          );
        })}
      </ScrollContainer>

      <div
        css={css`
          display: none;
          justify-content: space-between;
          flex-direction: column;
          ${Tablet} {
            display: flex;
            max-width: 537px;
            flex-direction: row;
            margin: 0 auto;
            justify-content: space-between;
          }
          ${Desktop} {
            display: flex;
            max-width: 100%;
            height: 347px;
            flex-direction: row;
            justify-content: space-between;
          }
        `}
      >
        {/* 三个卡片 */}
        {new Array(3).fill(0).map((val, index) => {
          return (
            <Card
              text={`${data}.description-${index + 1}`}
              img={`${data}.img-${index + 1}`}
              key={index}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default HowItWorks;
