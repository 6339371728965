import React, { useState } from "react";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";

const Tag = ({ text = "" }) => {
  return (
    <div
      css={css`
        display: ${text === "" ? "none" : "inline-block"};
        padding: 5px 10px;
        border-radius: 41px;
        background-color: #f2f2f2;
        width: fit-content;
        height: 27px;

        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.12px;
        text-align: left;
        color: #000000;
        white-space: nowrap;
        margin: 0 10px 0 0;
        ${Tablet} {
          margin: 20px 10px 0 0;
        }
        ${Desktop} {
          margin: 0 10px 0 0;
        }
      `}
    >
      {text}
    </div>
  );
};

export default Tag;
